import { Controller } from "stimulus"
const $ = require("jquery")

export default class extends Controller {

  connect() {
    $(".plan-switcher button").click((e) => {
      const button = $(e.target).closest("button")

      $(`.plan-switcher button[data-interval=${button.data("toggle")}]`).removeClass("active")
      $(`.plan-switcher button[data-interval=${button.data("interval")}]`).addClass("active")
      $("#pricing-table").removeClass(button.data("toggle"))
      $("#pricing-table").addClass(button.data("interval"))
    })

    $(".pricing-tab").click((e) => {
      const tab = $(e.target)

      $("#pricing-tabs .pricing-tab").removeClass("active")
      tab.addClass("active")

      $(".pricing-free, .pricing-starter, .pricing-full, .pricing-extra").removeClass("active")
      $("."+tab.data("target")).addClass("active")
    })

    $('.popover-trigger').popover({
	    container: 'body',
	    trigger: 'hover'
	  })
  }
}
