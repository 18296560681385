import { Controller } from "stimulus"
const $ = require("jquery")

export default class extends Controller {

  connect() {
    setTimeout(() => {
      window.swaggerUi = new window.SwaggerUi({
        url: "https://app.factures.com/api/doc.json",
        dom_id: "swagger-ui-container",
        supportedSubmitMethods: ['get', 'post', 'put', 'delete'],
        onComplete: function(swaggerApi, swaggerUi){
          if(console) {
            console.log("Loaded SwaggerUI");
          }
          $('pre code').each(function(i, e) {hljs.highlightBlock(e)});
        },
        onFailure: function(data) {
          if(console) {
            console.log("Unable to Load SwaggerUI");
            console.log(data);
          }
        },
        docExpansion: "none"
      });

      $('#input_apiKey').change(function() {
        var key = $('#input_apiKey')[0].value;
        console.log("key: " + key);
        if(key && key.trim() != "") {
          console.log("added key " + key);
          window.authorizations.add("key", new ApiKeyAuthorization("api_key", key, "query"));
        }
      })

      window.swaggerUi.load();
    }, 2000)
  }
}
